<template>
  <homepage-section
    :variant="showVariant ? 'origami' : 'plum'"
    :class="{ 'content row' : showVariant }">
    <content-wrapper>
      <content-stack
        class="plans-content"
        :class="{ 'variant-plans-content': showVariant }">
        <content-wrapper
          :max-width="686"
          :style="{'--vertical-margin': showVariant ? '0px' : '24px'}">
          <HomepageRedesignExperiment>
            <template #default>
              <homepage-text
                tag="h2"
                class="text-center text-white"
                styling-variant="heading1">
                Plans starting from {{ plans.capsule.basePrice }}
              </homepage-text>
            </template>
            <template #variantRedesign>
              <SectionHeadline
                :title="`Plans starting from ${plans.capsule.basePrice}`"/>
            </template>
          </HomepageRedesignExperiment>
        </content-wrapper>
        <component
          :is="isMobile ? 'content-stack' : 'content-row'"
          :style="{
            '--vertical-margin': showVariant ? '0px' : '24px',
            'margin-top': showVariant ? '8px !important' : '24px !important',
            'margin-bottom': showVariant ? '16px !important' : '24px !important'
          }"
          class="benefits">
          <content-stack
            v-for="benefit in benefits"
            :key="benefit"
            :spacing="showVariant ? 0 : 8"
            class="benefit"
            :class="{ 'border-top': !showVariant }">
            <HomepageRedesignExperiment>
              <template #default>
                <svg-check
                  :size="80"
                  :stroke-width=".5"
                  class="plan-check"
                  :class="{ 'mt-0': showVariant }"
                  variant="secondary"/>
              </template>
              <template #variantRedesign>
                <svg-check-outline class="plan-check"/>
              </template>
            </HomepageRedesignExperiment>
            <HomepageRedesignExperiment>
              <template #default>
                <homepage-text
                  tag="p"
                  class="benefit-text text-white"
                  styling-variant="heading2">
                  {{ benefit }}
                </homepage-text>
              </template>
              <template #variantRedesign>
                <h3>{{ benefit }}</h3>
              </template>
            </HomepageRedesignExperiment>
          </content-stack>
        </component>
        <component
          :is="isMobile ? 'content-stack' : 'content-row'"
          :style="{'--vertical-margin': showVariant ? '0px' : '24px', }">
          <marketing-button
            type="a"
            target="_self"
            :href="variant === 'explore-plans' ? '/howitworks/#plans' : getStyleQuizUrl()"
            :event-label="variant === 'explore-plans' ? 'Explore Our Plans' : 'Plans Get Started'"
            :variant="showVariant ? 'primary' : 'secondary'"
            :class="{ 'variant-marketing-button' : showVariant}">
            {{ variant === 'explore-plans' ? 'Explore Our Plans' : 'Get Started' }}
          </marketing-button>
        </component>
      </content-stack>
    </content-wrapper>
  </homepage-section>
</template>

<script>
import ContentStack from '@/homepage/components/common/ContentStack.vue'
import ContentRow from '@/homepage/components/common/ContentRow.vue'
import ContentWrapper from '@/homepage/components/common/ContentWrapper.vue'
import HomepageSection from '@/homepage/components/common/HomepageSection.vue'
import HomepageText from '@/homepage/components/common/HomepageText.vue'
import MarketingButton from '@/homepage/components/common/MarketingButton.vue'
import SvgCheck from '@/homepage/components/common/SvgCheck.vue'
import SvgCheckOutline from '@/homepage/components/common/SvgCheckOutline.vue'
import HomepageRedesignExperiment from '@shared/components/HomepageRedesignExperiment.vue'
import SectionHeadline from '@/components/global/SectionHeadline.vue'
import { useStyleQuizUrl } from '@/homepage/composables/styleQuizUrl.js'
import { useHomepageRedesignExperiment } from '@shared/composables/experiment.js'

export default {
  components: {
    ContentWrapper,
    ContentRow,
    HomepageSection,
    HomepageText,
    ContentStack,
    MarketingButton,
    SvgCheck,
    SvgCheckOutline,
    HomepageRedesignExperiment,
    SectionHeadline
  },
  props: {
    variant: {
      type: String,
      default: 'explore-plans'
    }
  },
  setup () {
    const { showVariant } = useHomepageRedesignExperiment()
    const { getStyleQuizUrl } = useStyleQuizUrl()
    return {
      getStyleQuizUrl,
      showVariant
    }
  },
  data () {
    return {
      plans: window.armoire.plans,
      benefits: [
        'Free Cleaning',
        'Free Shipping',
        'Free Styling'
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 0px;
  margin-top: 64px;
}

.plans-content, .benefits {
  width: 100%;
}

.variant-plans-content {
  margin-top: 0px;
  margin-bottom: 32px;
}

.border-top {
  border-top: $default-border;
}

.benefit {
  width: 100%;
  @include medium {
    width: 30%;
  }
}

.benefit-text {
  @include medium {
    max-width: 160px;
  }

  @include extralarge {
    max-width: unset;
  }
}

.plan-check {
  border: 2px solid $white;
  border-radius: 50%;
  padding: 4px;
}

.variant-marketing-button {
  text-transform: none;
  font-weight: 600;
  letter-spacing: normal;
}

.origami {
  background-color: $origami;
}
</style>
