<template>
  <div>
    <PreviewImage
      class="mb-sm"
      :src="props.imageUrl"
      :alt="props.imageAltText ? props.imageAltText : 'testimonial image'"/>
    <div class="quote-wrapper">
      <p class="quote text-armor">
        "{{ props.text }}"
      </p>
    </div>
  </div>
</template>

<script setup>
import PreviewImage from '@shared/components/ADORN/PreviewImage.vue'
const props = defineProps({
  text: {
    type: String,
    required: true
  },
  imageUrl: {
    type: String,
    required: true
  },
  imageAltText: {
    type: String,
    default: 'testimonial image',
    required: false
  }
})
</script>

<style lang="scss" scoped>
.quote-wrapper {
  height: 100px;
}
</style>
