<template>
  <marketing-button
    type="a"
    target="_self"
    :variant="buttonVariant"
    aria-label="Navigate to style quiz"
    :event-label="`${location} Get Started`"
    :href="getStyleQuizUrl()"
    :class="{ 'variant-marketing-button': showVariant }">
    Get Started
  </marketing-button>
</template>
<script>
import { useStyleQuizUrl } from '@/homepage/composables/styleQuizUrl.js'

import MarketingButton from './MarketingButton.vue'
import { useHomepageRedesignExperiment } from '@shared/composables/experiment.js'

export default {
  name: 'GetStartedButton',
  components: {
    MarketingButton
  },
  props: {
    buttonVariant: {
      type: String,
      default: 'primary',
      validator: value => {
        return value.match(/(primary|secondary|tertiary)/)
      }
    },
    location: {
      type: String,
      required: true
    }
  },
  setup () {
    const { getStyleQuizUrl } = useStyleQuizUrl()
    const { showVariant } = useHomepageRedesignExperiment()
    return {
      getStyleQuizUrl,
      showVariant
    }
  }
}
</script>

<style lang="scss" scoped>
  .variant-marketing-button {
    text-transform: none;
    font-weight: 600;
    letter-spacing: normal;
  }
</style>
