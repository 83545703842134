import '@/vue2_compat.js'

import { isBeta, isProd } from '@shared/utils/environment.js'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from '@/homepage/App.vue'
import router from '@/homepage/router.js'

import vClickOutside from 'click-outside-vue3'
import VueProgressiveImage from 'vue-progressive-image'
import 'vue-progressive-image/dist/style.css'
import { DisplaySizeMixin } from '@/homepage/components/common/DisplaySizeMixin.js'

import VueIntercom from '@/homepage/plugins/intercom.js'
import VueSegment from '@/plugins/segment.js'
import { createStore } from 'vuex'

const app = createApp(App)
app.use(VueProgressiveImage, { delay: 0 })
app.use(VueIntercom)

app.use(createPinia())
app.use(createStore()) // Empty Vuex store to suppress warnings.
app.use(router)

app.use(vClickOutside)

app.mixin(DisplaySizeMixin)

// Segment Analytics
let vOptions = {}
if (isProd) {
  vOptions = {
    id: process.env.VUE_APP_SEGMENT_KEY_PROD,
    sourceId: process.env.VUE_APP_SEGMENT_SOURCE_PROD
  }
} else if (isBeta) {
  vOptions = {
    id: process.env.VUE_APP_SEGMENT_KEY_BETA,
    sourceId: process.env.VUE_APP_SEGMENT_SOURCE_BETA
  }
} else {
  // Development
  vOptions = {
    id: process.env.VUE_APP_SEGMENT_KEY_DEV,
    sourceId: process.env.VUE_APP_SEGMENT_SOURCE_DEV
  }
}
app.use(VueSegment, vOptions)
app.mount('#app')
