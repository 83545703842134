<template>
  <div class="row bg-origami mt-xl">
    <HomepageSection
      title="Named Best Professional Styling"
      :paragraph="paragraphText">
      <template #between-headline-and-content>
        <img
          class="mb-md"
          width="222"
          height="33"
          :src="getImageUrl('logos/cosmo.svg')">
      </template>
      <template #default>
        <TrackingButton
          :fixed-width="isTabletOrSmaller"
          class="mb-xl"
          app-entry-point="homepage"
          event-label="Personal Styling"
          text="Learn More"
          to="https://armoire.style/personal-styling/"/>
      </template>
    </HomepageSection>
  </div>
</template>
<script setup>
import { getImageUrl } from '@/homepage/utils/url.js'
import useScreenSize from '@shared/composables/screenSize.js'
import TrackingButton from '@shared/components/ADORN/TrackingButton.vue'
import HomepageSection from './HomepageSection.vue'

const { isTabletOrSmaller } = useScreenSize()
const paragraphText = 'All members have access to free personal styling with a human who cares.'
</script>
