<!-- An AutoScroll Carousel with a configurable number of items per slide. -->
<template>
  <div
    :id="id"
    ref="responsiveCarousel"
    class="carousel slide">
    <div class="carousel-inner">
      <div
        v-for="(chunk, chunkIndex) in chunkedItems"
        :key="chunkIndex"
        :class="['carousel-item', { active: chunkIndex === 0 }]">
        <div class="row">
          <div
            v-for="(item, itemIndex) in chunk"
            :key="itemIndex"
            :class="columnClass">
            <slot :item="item"/>
          </div>
        </div>
      </div>
    </div>
    <div :class="{'overlay' : $slots.overlay}">
      <slot
        v-if="$slots.overlay"
        name="overlay"
        :active-items="activeItems"/>
      <Teleport
        v-if="!disableIndicators"
        :to="indicatorContainer"
        :disabled="!teleportIndicators">
        <div
          class="carousel-indicators">
          <button
            v-for="(chunk, chunkIndex) in chunkedItems"
            :key="chunkIndex"
            :data-bs-target="`#${id}`"
            :data-bs-slide-to="chunkIndex"
            :class="{ active: chunkIndex === 0 }"
            :aria-current="chunkIndex === 0"/>
        </div>
      </Teleport>
    </div>
    <Teleport
      :to="buttonContainer"
      :disabled="!teleportScrollButtons">
      <ScrollButton
        direction="left"
        :data-bs-target="`#${id}`"
        data-bs-slide="prev"
        :class="{'visually-hidden-focusable': !showButtons, 'carousel-control-prev' : !teleportScrollButtons}"/>
      <ScrollButton
        direction="right"
        :data-bs-target="`#${id}`"
        data-bs-slide="next"
        :class="{'visually-hidden-focusable': !showButtons, 'carousel-control-next' : !teleportScrollButtons}"/>
    </Teleport>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useEventListener } from '@vueuse/core'
import { Carousel } from 'bootstrap'
import useScreenSize from '@shared/composables/screenSize.js'
import useComponentID from '@/composables/useComponentId.js'
import ScrollButton from '@shared/components/ADORN/ScrollButton.vue'

const props = defineProps({
  items: {
    type: Array,
    required: true
  },
  itemsPerSlide: {
    type: [Object, Number],
    default: () => ({
      mobile: 1,
      tablet: 2,
      desktop: 3
    }),
    validator (value) {
      if (typeof value === 'object') {
        const requiredProps = ['mobile', 'tablet', 'desktop']
        return requiredProps.every(prop => prop in value)
      }
      return true
    }
  },
  interval: {
    type: Number,
    default: 5000
  },
  showButtons: {
    type: Boolean,
    default: false
  },
  buttonContainer: {
    type: [String, HTMLElement],
    default: () => null
  },
  showIndicators: {
    type: Boolean,
    default: true
  },
  indicatorContainer: {
    type: [String, HTMLElement],
    default: () => null
  },
  pause: {
    type: Boolean,
    default: false
  }
})
const id = useComponentID('autoscroll-carousel')
const { screenSizeValue } = useScreenSize()

const getChunks = (items, chunkSize) => {
  const chunks = []
  for (let i = 0; i < items.length; i += chunkSize) {
    chunks.push(items.slice(i, i + chunkSize))
  }
  return chunks
}

const chunkSize = computed(() => {
  if (typeof props.itemsPerSlide === 'number') {
    return props.itemsPerSlide
  }
  return screenSizeValue(props.itemsPerSlide).value
})

const columnClass = computed(() => {
  const sizeValue = screenSizeValue(props.itemsPerSlide).value
  if (Array.isArray(sizeValue)) {
    return sizeValue.map(size => `col-${12 / size}`).join(' ')
  }
  return `col-${12 / sizeValue}`
})

const chunkedItems = computed(() => {
  return getChunks(props.items, chunkSize.value)
})
const activeIndex = ref(0)
const activeItems = computed(() => chunkedItems.value[activeIndex.value])

const responsiveCarousel = ref(null)
const carouselInstance = ref(null)
const teleportScrollButtons = computed(() => props.buttonContainer !== null)
const teleportIndicators = computed(() => props.indicatorContainer !== null)
const disableIndicators = computed(() => !props.showIndicators || (props.items.length === chunkSize.value))

const prefersReducedMotion = computed(() => window.matchMedia('(prefers-reduced-motion: reduce)').matches)
const initializeCarousel = () => {
  if (carouselInstance.value) {
    carouselInstance.value.dispose()
  }
  carouselInstance.value = Carousel.getOrCreateInstance(responsiveCarousel.value, {
    interval: props.interval,
    ride: (prefersReducedMotion.value || props.pause) ? false : 'carousel',
    touch: true
  })
}

watch(() => props.pause, (pause) => {
  carouselInstance.value?.[pause ? 'pause' : 'cycle']()
})

onMounted(() => {
  initializeCarousel()
})

useEventListener(responsiveCarousel, 'slide.bs.carousel', ({ to }) => {
  activeIndex.value = to
})

defineExpose({ id, activeItems })
</script>

<style lang="scss" scoped>
.carousel-control-prev, .carousel-control-next {
  opacity: unset;
  --button-indent: 4px;
  position: absolute;
  top: 40%;
}
.carousel-control-prev {
  left: var(--button-indent);
}
.carousel-control-next {
  right: var(--button-indent);
}
.carousel-indicators {
  display: flex;
  justify-content: center;
  position: static;
  margin: 0;
  column-gap: $spacing-md;

  --indicator-size: 12px;
  @include media-tablet-or-larger {
    --indicator-size: 16px;
  }

  & > button {
    width: var(--indicator-size);
    height: var(--indicator-size);
    border-radius: 50%;
    border: 0.5px solid $pewter;
    margin: 0;

    &.active {
      background-color: $pewter;
    }
  }
}
.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: $spacing-lg;
  background-color: rgb($white, 0.75);
  color: $armor;
}
</style>
