<template>
  <HeroImage/>
  <RentingMadeEasySection/>
  <CuratedCollections/>
  <FavoriteBrandsSection id="brands"/>
  <PlansForEveryBudget id="plans"/>
  <PersonalStylingSection/>
  <WhatMembersSay/>
  <InTheNewsSection/>
  <EmailCaptureSection/>
</template>

<script setup>
import { onActivated } from 'vue'
import HeroImage from '@/homepage/components/revamp/HeroImage.vue'
import RentingMadeEasySection from '@/homepage/components/revamp/RentingMadeEasySection.vue'
import CuratedCollections from '@/homepage/components/CuratedCollections.vue'
import PlansForEveryBudget from '@/homepage/components/revamp/PlansForEveryBudget.vue'
import PersonalStylingSection from '@/homepage/components/revamp/PersonalStylingSection.vue'
import EmailCaptureSection from '@/homepage/components/revamp/EmailCaptureSection.vue'
import InTheNewsSection from '@/homepage/components/revamp/InTheNewsSection.vue'
import FavoriteBrandsSection from '@/homepage/components/revamp/FavoriteBrandsSection.vue'
import WhatMembersSay from '@/homepage/components/revamp/WhatMembersSay.vue'
import useAnalytics from '@shared/composables/analytics.js'

const { trackPageView } = useAnalytics()

onActivated(() => {
  trackPageView('Homepage')
})
</script>

<style scoped lang="scss">
#brands, #plans { @include anchor-link; }
</style>
