<!--
Horizontal scroll component that scrolls horizontally when it receives vertical scroll events.
-->
<template>
  <HorizontalScroll
    ref="horizontalScroll"
    :show-buttons="showButtons">
    <slot/>
  </HorizontalScroll>
</template>

<script setup>
import { computed, ref } from 'vue'
import useScrollWheel from '@shared/composables/scrollWheel.js'
import HorizontalScroll from '@shared/components/ADORN/HorizontalScroll.vue'

defineProps({
  showButtons: {
    type: Boolean,
    default: false
  }
})

const horizontalScroll = ref(null)
const isVerticalScrolling = ref(false)
const isScrolling = computed(() => isVerticalScrolling.value || horizontalScroll.value?.isScrolling)
let verticalScrollTimeout = null

useScrollWheel(() => horizontalScroll.value?.scrollContent, (event, element) => {
  const { deltaX, deltaY } = event
  const isVerticalScroll = Math.abs(deltaY) > Math.abs(deltaX)
  if (isVerticalScroll) {
    // Allow vertical scroll to pass through when we've reached the end of the horizontal scroll.
    const { canScrollLeft, canScrollRight } = horizontalScroll.value
    if ((canScrollLeft && deltaY < 0) || (canScrollRight && deltaY > 0)) {
      event.preventDefault()
      element.scrollLeft += deltaY
    }

    isVerticalScrolling.value = true
    clearTimeout(verticalScrollTimeout)
    verticalScrollTimeout = setTimeout(() => {
      isVerticalScrolling.value = false
    }, 100)
  }
})

defineExpose({ isScrolling })
</script>
