import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useGenericContentStore } from '@/store/modules/genericContent'
import { useClosetStore } from '@shared/stores/closet'

export const useHomepageStore = defineStore('homepage-store', () => {
  const genericContentStore = useGenericContentStore()
  const closetStore = useClosetStore()

  const initialDataLoaded = computed(() => {
    return closetStore.initialSectionsLoaded && genericContentStore.initialSectionsLoaded
  })

  const loadInitialData = () => {
    closetStore.loadInitialData()
    genericContentStore.loadInitialData()
  }

  return {
    initialDataLoaded,
    loadInitialData
  }
})
