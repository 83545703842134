import { defineStore } from 'pinia'
import { ref } from 'vue'
import { getTopBrands } from '@shared/api/brands.js'

export const useBrandsStore = defineStore('brands', () => {
  const topBrands = ref([])

  async function fetchTopBrands () {
    const res = await getTopBrands()
    topBrands.value = res.data
  }

  return {
    topBrands,
    fetchTopBrands
  }
})
