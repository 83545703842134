<template>
  <homepage-section
    :class="{ 'white': whiteVariant}">
    <content-wrapper>
      <content-stack :spacing="isMobile ? 16 : 24">
        <homepage-text
          tag="h2"
          class="text-center"
          :class="{ 'text-white': plumVariant}"
          styling-variant="heading1">
          Clothing Rental Made Easy
        </homepage-text>
        <component
          :is="isMobile ? 'snap-scroll' : 'content-row'"
          :spacing="8"
          :length="steps.length"
          :dot-variant="plumVariant? 'white':'gray'"
          class="flex-wrap">
          <plain-button
            v-for="(step, idx) in steps"
            :key="step.title1"
            type="a"
            :href="getStyleQuizUrl()"
            aria-label="Start Armoire style quiz"
            class="renting-step scroll-item"
            :class="isMobile ? 'pt-0' : 'border-top' ">
            <content-stack :spacing="8">
              <homepage-text
                tag="h3"
                styling-variant="heading2"
                :class="{ 'text-white': plumVariant}">
                {{ idx + 1 }}<br>{{ step.title1 }}<br><homepage-text
                  tag="span"
                  styling-variant="heading3">
                  {{ step.title2 }}
                </homepage-text>
              </homepage-text>
              <homepage-image
                v-if="inViewport.now && whiteVariant"
                :height="120"
                width="auto"
                :alt="step.imageAlt"
                :webp-source="`rentsteps/rentstep${idx+1}.webp`"
                :source="`rentsteps/rentstep${idx+1}.png`"
                source-type="image/png"/>
              <homepage-image
                v-if="inViewport.now && plumVariant"
                :height="120"
                width="auto"
                :alt="step.imageAlt"
                :webp-source="`rentstep${idx+1}.webp`"
                :source="`rentstep${idx+1}.png`"
                source-type="image/png"/>
              <homepage-text
                :styling-variant="isMobile ? 'copy' : 'copy-small'"
                :class="{ 'text-white': plumVariant}">
                {{ step.description }}
              </homepage-text>
            </content-stack>
          </plain-button>
        </component>
        <div class="renting-made-easy-btns">
          <get-started-button
            v-if="showGetStarted"
            location="Renting Made Easy"
            :button-variant="whiteVariant ? 'primary' : 'secondary'"/>
          <marketing-button
            v-if="whiteVariant"
            type="a"
            target="_self"
            aria-label="Navigate to how it works page"
            event-label="How It Works"
            href="/howitworks"
            variant="secondary">
            How Armoire Works
          </marketing-button>
          <marketing-button
            v-if="plumVariant && showLearnMore"
            type="a"
            target="_self"
            aria-label="Navigate to how it works page"
            event-label="Learn More"
            href="/howitworks"
            variant="tertiary">
            Learn More
          </marketing-button>
        </div>
      </content-stack>
    </content-wrapper>
  </homepage-section>
</template>

<script>
import ContentRow from '@/homepage/components/common/ContentRow.vue'
import ContentStack from '@/homepage/components/common/ContentStack.vue'
import ContentWrapper from '@/homepage/components/common/ContentWrapper.vue'
import HomepageImage from '@/homepage/components/common/HomepageImage.vue'
import HomepageSection from '@/homepage/components/common/HomepageSection.vue'
import HomepageText from '@/homepage/components/common/HomepageText.vue'
import MarketingButton from '@/homepage/components/common/MarketingButton.vue'
import PlainButton from '@/homepage/components/common/PlainButton.vue'
import GetStartedButton from '@/homepage/components/common/GetStartedButton.vue'
import SnapScroll from './SnapScroll.vue'
import inViewport from 'vue-in-viewport-mixin'
import { useStyleQuizUrl } from '@/homepage/composables/styleQuizUrl.js'

export default {
  components: {
    ContentRow,
    HomepageSection,
    HomepageText,
    HomepageImage,
    ContentStack,
    MarketingButton,
    ContentWrapper,
    PlainButton,
    SnapScroll,
    GetStartedButton
  },
  mixins: [inViewport],
  props: {
    showLearnMore: {
      type: Boolean,
      default: true
    },
    showGetStarted: {
      type: Boolean,
      default: true
    },
    variant: {
      type: String,
      default: 'plum',
      validator: value => {
        return value.match(/(plum|white)/)
      }
    }
  },
  setup () {
    const { getStyleQuizUrl } = useStyleQuizUrl()
    return {
      getStyleQuizUrl
    }
  },
  data () {
    return {
      steps: [
        {
          title1: 'Take',
          title2: 'our style quiz',
          description: 'Help us get to know you better with a quick 5-minute quiz.',
          imageAlt: 'Decorative illustration of taking the style quiz'
        },
        {
          title1: 'Choose',
          title2: 'your styles',
          description: 'Select items from your dynamic, personalized closet. We\'ll ship them to your door.',
          imageAlt: 'Decorative illustration of choosing styles'
        },
        {
          title1: 'Wear',
          title2: 'and enjoy',
          description: 'Keep your styles as long as you want. Laundry and normal wear & tear are always on us!',
          imageAlt: 'Decorative illustration of wearing styles'
        },
        {
          title1: 'Swap',
          title2: '(It\'s easy!)',
          description: 'When you\'re ready, choose new items. Enjoy your current styles until your new case arrives.',
          imageAlt: 'Decorative illustration of swapping styles'
        }
      ]
    }
  },
  computed: {
    whiteVariant () {
      return this.variant === 'white'
    },
    plumVariant () {
      return this.variant === 'plum'
    }
  }
}
</script>

<style lang="scss" scoped>
.renting-made-easy-btns {
  display: flex;
  flex-direction: column;
  @include medium {
    flex-direction: row;
  }
  :first-child {
    margin-bottom: 1rem;
    @include medium {
      margin: 0 1rem;
    }
  }
}
.renting-step {
  width: 100%;
  padding: 0 24px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;

  @include medium {
    padding: 12px;
    height: 340px;
    margin-bottom: 40px;
    width: 40%;
  }

  @include extralarge {
    width: 23%;
    padding: 24px 0;
    height: 380px;
  }

  .rent-step-image {
    height: 100px;
    width: auto;
  }
}
</style>
