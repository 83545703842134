<template>
  <div>
    <HomepageTestExperiment/>
    <intro-banner
      class="banner"/>
    <benefits-banner/>
    <renting-made-easy
      v-bind="inViewportProps"
      variant="white"/>
    <featured-brands v-bind="inViewportProps"/>
    <variety-convenience v-bind="inViewportProps"/>
    <plans-callout v-bind="inViewportProps"/>
    <stylish-smart-personal v-bind="inViewportProps"/>
    <for-by-women v-bind="inViewportProps"/>
    <nicest-place v-bind="inViewportProps"/>
    <real-members-real-style v-bind="inViewportProps"/>
    <news-sources v-bind="inViewportProps"/>
    <email-capture-homepage v-bind="inViewportProps"/>
  </div>
</template>

<script>
import HomepageTestExperiment from '@/homepage/components/HomepageTestExperiment.vue'
import BenefitsBanner from './BenefitsBanner.vue'
import EmailCaptureHomepage from './EmailCaptureHomepage.vue'
import ForByWomen from './ForByWomen.vue'
import IntroBanner from './IntroBanner.vue'
import NewsSources from './NewsSources.vue'
import NicestPlace from './NicestPlace.vue'
import FeaturedBrands from './FeaturedBrands.vue'
import VarietyConvenience from './VarietyConvenience.vue'
import RentingMadeEasy from './RentingMadeEasy.vue'
import StylishSmartPersonal from './StylishSmartPersonal.vue'
import RealMembersRealStyle from './RealMembersRealStyle.vue'
import PlansCallout from './PlansCallout.vue'
import useAnalytics from '@shared/composables/analytics.js'

export default {
  components: {
    HomepageTestExperiment,
    BenefitsBanner,
    FeaturedBrands,
    EmailCaptureHomepage,
    ForByWomen,
    IntroBanner,
    NewsSources,
    NicestPlace,
    VarietyConvenience,
    RentingMadeEasy,
    StylishSmartPersonal,
    RealMembersRealStyle,
    PlansCallout
  },
  setup () {
    const { trackPageView } = useAnalytics()
    return { trackPageView }
  },
  data () {
    return {
      inViewportProps: {
        'in-viewport-root-margin': '20% 0%',
        'in-viewport-once': true
      }
    }
  },
  activated () {
    this.trackPageView('Homepage')
  }
}
</script>

<style lang="scss" scoped>
.banner {
  margin-top: 80px;
}
</style>
