<!-- Anonymous experiment testing -->
<template>
  <div ref="wrapper">
    <BaseExperiment :name="name">
      <template #default>
        <slot>
          <div class="d-none">
            {{ comment }}
          </div>
        </slot>
      </template>
      <template #variantTest>
        <slot name="variantTest">
          <div class="d-none">
            {{ comment }}
          </div>
        </slot>
      </template>
    </BaseExperiment>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import BaseExperiment from '@shared/components/BaseExperiment.vue'
import useExperiment from '@shared/composables/experiment.js'
import { useElementVisibility } from '@vueuse/core'

const { name, isActive, variant, showVariant, showOriginal } = useExperiment('homepage_test', {
  variantNames: ['test'],
  showOriginalWhenInactive: true
})
const comment = computed(() => `Experiment: ${name}${isActive.value ? `, Variant: ${variant.value}` : ' (inactive)'}`)

const wrapper = ref(null)
const shown = ref(false)
const visible = useElementVisibility(wrapper)
watch(visible, (value) => {
  if (value && !shown.value) {
    shown.value = true
    if (window.analytics) {
      window.analytics.track('HomepageTestExperiment Shown', {
        status: (showOriginal.value || showVariant.value) ? `Showing ${variant.value} variant` : 'hidden'
      })
    }
  }
})
</script>
